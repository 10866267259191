import Auth from '../layouts/auth';
import LoginComponent from '../components/auth/Login';
import { useRouter } from 'next/router';
import { useDispatch } from 'react-redux';
import { useCallback, useEffect } from 'react';
import { createWixConnection } from 'actions/channel';
import { attachUserCatalog } from 'actions/user';
import message from '../components/utils/message';
import capture from '../tools/capture';

export default function Home() {
  const router = useRouter();
  const { state, code } = router.query;
  const dispatch = useDispatch();
  const handleWixConnection = useCallback(async () => {
    if (code) {
      try {
        let response = await dispatch(
          createWixConnection({
            catalogId: state,
            publicToken: code,
          })
        );
        await dispatch(attachUserCatalog()); // Fetch catalog data
        await router.push(`/channel/${state}/edit/channel`);
      } catch (error) {
        capture(error);
        console.log('error', error);
        message.error('Errore');
      }
    }
  }, [code, dispatch, router, state]);
  useEffect(() => {
    handleWixConnection();
  }, [handleWixConnection]);
  return (
    <Auth>
      <LoginComponent />
    </Auth>
  );
}
